// src/components/pages/Login.js

import React from "react";

const Login = () => {
  return (
    <div>
      <h2>Veuillez vous connecter</h2>
    </div>
  );
};

export default Login;
