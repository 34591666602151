// src/components/layout/Layout.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route, Navigate } from "react-router-dom";
import Menu from "./Menu";
import { MENU_PAGES, DEFAULT_PAGE } from "../../config/menuPages";

// Import dynamique des composants de page
const pageComponents = {
  Home: React.lazy(() => import("../pages/Home")),
  Login: React.lazy(() => import("../pages/Login")),
  Sandbox: React.lazy(() => import("../pages/tests/Sandbox")),
  TestApiMasterPublications: React.lazy(() =>
    import("../pages/tests/TestApiMasterPublications")
  ),
  TestApiGSMensuelAbonnes: React.lazy(() =>
    import("../pages/tests/TestApiGSMensuelAbonnes")
  ),
  Abonnes: React.lazy(() => import("../pages/abonnes/Abonnes")),
  Publications: React.lazy(() => import("../pages/publications/Publications")),
  // Vues: React.lazy(() => import("../pages/vues/Vues")),
  Press: React.lazy(() => import("../pages/press/Press")),
};

const Layout = ({ user, signOut }) => {
  const renderRoutes = (pages) =>
    pages.flatMap(({ path, component, subPages }) => {
      if (subPages) {
        return renderRoutes(subPages);
      }
      // Vérifier si le composant existe dans pageComponents
      if (component && pageComponents[component]) {
        const PageComponent = pageComponents[component];
        return (
          <Route
            key={path}
            path={path}
            element={
              <React.Suspense fallback={<div>Chargement...</div>}>
                <PageComponent />
              </React.Suspense>
            }
          />
        );
      }
      console.warn(`No component found for path: ${path}`);
      return [];
    });
  // infos sur l'utilisateur
  // Vérifiez si 'signInUserSession' et 'accessToken' existent avant d'accéder à leurs propriétés
  const userGroups =
    user?.signInUserSession?.accessToken?.payload["cognito:groups"] || [];
  console.log("USER INFO : ", user);
  console.log("User Groups : ", userGroups);
  const userName =
    user.loginId ||
    (user.signInDetails && user.signInDetails.loginId) ||
    "Utilisateur inconnu";

  return (
    <Container fluid className="vh-100 p-0">
      <Row className="h-100 g-0">
        <Col xs={2} className="bg-light border-end position-fixed h-100">
          <Menu user={user} signOut={signOut} />
        </Col>
        <Col xs={2}>
          {/* Colonne vide fixe pour occuper l'espace du menu qui est flottant */}
        </Col>
        <Col xs={10} className="p-3">
          <React.Suspense fallback={<div>Chargement...</div>}>
            <Routes>
              <Route
                path="/"
                element={<Navigate to={DEFAULT_PAGE} replace />}
              />
              {renderRoutes(MENU_PAGES)}
            </Routes>
          </React.Suspense>
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
