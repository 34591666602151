// src/App.js

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsconfig from "./aws-exports";
import Layout from "./components/layout/Layout";
import Login from "./components/pages/Login";
import "./styles/global.css";

Amplify.configure(awsconfig);

function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <Router>
          <Routes>
            {/* Si l'utilisateur n'est pas connecté, redirige vers Login */}
            {!user ? (
              <Route path="*" element={<Login />} />
            ) : (
              <>
                <Route
                  path="*"
                  element={<Layout user={user} signOut={signOut} />}
                />
              </>
            )}
          </Routes>
        </Router>
      )}
    </Authenticator>
  );
}
export default App;
