// src/components/layout/Menu.js
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { MENU_PAGES } from "../../config/menuPages";
import { ACCESS_LEVELS } from "../../config/menuPages";
import SessionWidget from "../sharedComponents/widgets/SessionWidget";

const Menu = ({ user, signOut }) => {
  const location = useLocation();
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const toggleSubMenu = (path) => {
    setOpenSubMenu(openSubMenu === path ? null : path);
  };

  // Vérifie si l'utilisateur a accès à la page
  const hasAccess = (accessLevel) => {
    const isAdmin =
      user &&
      user.signInUserSession &&
      user.signInUserSession.accessToken &&
      user.signInUserSession.accessToken.payload["cognito:groups"]?.includes(
        "Admins"
      );

    return (
      accessLevel === ACCESS_LEVELS.ALL ||
      (accessLevel === ACCESS_LEVELS.ADMIN && isAdmin)
    );
  };

  // Filtre les pages selon les droits d'accès
  const filterMenuPages = (pages) =>
    pages.filter((page) => hasAccess(page.accessLevel));

  return (
    <Nav className="flex-column bg-light p-3 h-100">
      <h3 className="mb-4">SENAT</h3>
      {filterMenuPages(MENU_PAGES).map(
        ({ path, label, icon: Icon, subPages }) => (
          <div key={path}>
            <Nav.Link
              as={Link}
              to={subPages ? undefined : path}
              onClick={() => subPages && toggleSubMenu(path)}
              active={location.pathname.startsWith(path)}
              className="mb-2 d-inline-block w-100 overflow-hidden"
            >
              <Icon className="me-2" /> {label}
            </Nav.Link>
            {subPages && openSubMenu === path && (
              <div style={{ paddingLeft: "20px" }}>
                {filterMenuPages(subPages).map(
                  ({ path: subPath, label: subLabel, icon: SubIcon }) => (
                    <Nav.Link
                      key={subPath}
                      as={Link}
                      to={subPath}
                      active={location.pathname === subPath}
                      className="mb-2 d-flex align-items-center"
                    >
                      <SubIcon className="me-2" /> {subLabel}
                    </Nav.Link>
                  )
                )}
              </div>
            )}
          </div>
        )
      )}
      <SessionWidget user={user} signOut={signOut} />
    </Nav>
  );
};

export default Menu;
