// src/components/sharedComponents/widgets/SessionWidget.js

import React from "react";
import { Button } from "react-bootstrap";

const SessionWidget = ({ user, signOut }) => {
  const userName =
    user.loginId ||
    (user.signInDetails && user.signInDetails.loginId) ||
    "Utilisateur inconnu";

  const userGroups =
    user?.signInUserSession?.accessToken?.payload["cognito:groups"] || [];

  return (
    <div className="border-top pt-4 mt-4 d-flex flex-column w-100">
      <small className="text-muted mb-2 text-truncate" title={userName}>
        {userName}
      </small>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={signOut}
        className="text-truncate"
      >
        Déconnexion
      </Button>
    </div>
  );
};

export default SessionWidget;
