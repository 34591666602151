// src/config/menuPages.js
import {
  FaHome,
  FaFlask,
  FaUserFriends,
  FaNewspaper,
  FaChartBar,
} from "react-icons/fa";

export const ACCESS_LEVELS = {
  ALL: "all", // Accessible à tous les utilisateurs authentifiés
  ADMIN: "admin", // Accessible uniquement aux administrateurs
};

export const MENU_PAGES = [
  {
    path: "/",
    label: "Accueil",
    icon: FaHome,
    component: "Home",
    accessLevel: ACCESS_LEVELS.ALL,
  },

  {
    path: "/abonnes",
    label: "Abonnés (GS)",
    icon: FaUserFriends,
    accessLevel: ACCESS_LEVELS.ALL,
    subPages: [
      {
        path: "/abonnes/all",
        label: "All",
        icon: FaUserFriends,
        component: "Abonnes",
        accessLevel: ACCESS_LEVELS.ALL,
      },
      {
        path: "/abonnes/barchart",
        label: "Bar Chart",
        icon: FaChartBar,
        component: "Followers",
        accessLevel: ACCESS_LEVELS.ALL,
      },
    ],
  },
  {
    path: "/publications",
    label: "Publications",
    icon: FaNewspaper,
    component: "Publications",
    accessLevel: ACCESS_LEVELS.ALL,
  },
  {
    path: "/press",
    label: "Presse",
    icon: FaNewspaper,
    component: "Press",
    accessLevel: ACCESS_LEVELS.ALL,
  },
  {
    path: "/tests",
    label: "Tests",
    icon: FaFlask,
    accessLevel: ACCESS_LEVELS.ALL,
    subPages: [
      {
        path: "/tests/sandbox",
        label: "Sandbox",
        icon: FaFlask,
        component: "Sandbox",
        accessLevel: ACCESS_LEVELS.ALL,
      },
      {
        path: "/tests/masterPubApi",
        label: "Master DB",
        icon: FaFlask,
        component: "TestApiMasterPublications",
        accessLevel: ACCESS_LEVELS.ALL,
      },
      {
        path: "/tests/apiGSMensuelAbonnes",
        label: "GS Abonnés",
        icon: FaFlask,
        component: "TestApiGSMensuelAbonnes",
        accessLevel: ACCESS_LEVELS.ALL,
      },
    ],
  },
];

// Page par défaut
export const DEFAULT_PAGE = "/publications";
